import React, { useEffect, useState } from 'react'
import '../css/form.css'

const Form = props => {
    const [formValues, setFormValues] = useState([{ fname: "", lname: "", gender: '', address: '', degree: '',id:Math.random().toString() }])
    const [errors,setErrors] = useState([{fname:false,lname:false,gender:false,address:false,degree:false}])

    useEffect(() => {
      localStorage.removeItem('formData')
    }, [])
    

      const handleChange = (id, e) => {
        e.stopPropagation();
        let newFormVal = formValues.map((item,i) => {
            if(item.id === id) {
                return { ...item,[e.target.name]:e.target.value}
            }else{
                return item
            }
        })
        setFormValues(newFormVal);
      }

      const handleChangeRadio = (id,val) => {
        let newFormValRadio = formValues.map((item,i) => {
            if(item.id === id) {
                return { ...item,gender:val}
            }else{
                return item
            }
        }) 
        setFormValues(newFormValRadio);
      }
    
    const addFormFields = () => {
        setFormValues([{ fname: "", lname: "", gender: '', address: '', degree: '' ,id:Math.random().toString()}, ...formValues])
    }

    const removeFormFields = (id) => {
        setFormValues(formValues.filter(item => item.id !== id))
    }

    const checkValidation = () => {
        let valid = false
        let newErr = formValues.map(item => {
            let obj = Object.entries(item)
            console.log('obj',obj)
            let customErr = {fname:false,lname:false,gender:false,address:false,degree:false}
            obj.forEach(self => {
                if(self[1] === '' && self[0] !== 'id'){
                    valid = false
                    customErr = {...customErr,[self[0]]:true}
                }else if(self[0] !== 'id'){
                   valid = true 
                }
            })
            console.log('item',item,customErr);

            return customErr
        })
        setErrors(newErr)
        return valid
    }
    
    const handleSubmit = (event) => {
        event.preventDefault();
        if(checkValidation()){
            setTimeout(()=>{
                localStorage.setItem('formData',JSON.stringify(formValues))
            },1000)
        }
    }
    return (
        <form onSubmit={handleSubmit} style={{ border: '1px solid #ccc' }}>
            <div className="container">
                <h1>Sign Up</h1>
                <p>Please fill in this form to create an account.</p>
                <div className='add_buttom_main'><button type='button' className="addbtn" onClick={addFormFields}>Add</button></div>
                {formValues.map((item,i) => {
                    return(
                        <div style={{ display: 'flex'}} key={item.id}> 
                        <div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ marginRight: '20px' }}>
                                    <label htmlFor="fname" data-testid='fname'><b>First Name</b></label>
                                    <input type="text" placeholder="Enter First Name" name="fname" value={item.fname} onChange={(e) => handleChange(item.id,e)}/>
                                    { errors && errors[i] && errors[i].fname && <span style={{color:'red'}}>First name  is required</span>}
                                </div>
                                <div>
                                    <label htmlFor="lname" data-testid='lname'><b>Last Name</b></label>
                                    <input type="text" placeholder="Enter Last Name" name="lname" value={item.lname} onChange={(e) => handleChange(item.id,e)}/>
                                    { errors && errors[i] && errors[i].lname && <span style={{color:'red'}}>Last name  is required</span>}
                                </div>
                            </div>
                            <div>
                                <p>Gender:</p>
                                <input type="radio" id={`${item.id}_female`} name={`gender_${item.id}`} value='female' onChange={(e) => handleChangeRadio(item.id,'female')} checked={item.gender === 'female'}/>
                                <label htmlFor={`${item.id}_female`}>Female</label>
                                <input type="radio" id={`${item.id}_male`} name={`gender_${item.id}`} value='male' onChange={(e) => handleChangeRadio(item.id,'male')} checked={item.gender === 'male'}/>
                                <label htmlFor={`${item.id}_male`}>Male</label>
                                { errors && errors[i] && errors[i].gender && <span style={{color:'red'}}>Please select gender</span>}
                            </div>
                            <div>
                                <label htmlFor="address"><b>Address</b></label><br />
                                <textarea id="address" name="address" rows="4" cols="50" placeholder='Enter Address' value={item.address} onChange={(e) => handleChange(item.id,e)}/>
                                { errors && errors[i] && errors[i].address && <span style={{color:'red'}}>Please Enter address</span>}
                            </div>
                            <div>
                                <label htmlFor="degree">Choose Your Degree:</label>
                                <select name="degree" id="degree" value={item.degree} onChange={(e) => handleChange(item.id,e)}>
                                    <option value=''>Select</option>
                                    <option value="be">B.E</option>
                                    <option value="bca">B.C.A</option>
                                    <option value="bba">B.B.A</option>
                                    <option value="bcom">B.COM</option>
                                </select>
                                { errors && errors[i] && errors[i].degree && <span style={{color:'red'}}>Please select degree</span>}<br /><br />
                            </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                            {formValues.length - 1 > 0 && <div><button type='button' data-testid={`btn_${i}`} onClick={() => removeFormFields(item.id)}>Remove</button></div>}
                        </div>
                    </div>
                    )
                })}
                <div className="clearfix">
                    <button type="submit" className="signupbtn">Save All</button>
                </div>
            </div>
        </form>
    )
}

export default Form;