import './App.css';
import Form from './component/Form';

function App() {
  return (
   <Form />
  );
}

export default App;
